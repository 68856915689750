import { gql } from 'graphql-request';

export const createUserMutation = gql`
        mutation User(
            $userLogin: String!
            $password: String!
            $source: String!
            $companies: [Users_Input_Company!]!
        ) {
            CreateUser (
                userLogin: $userLogin
                password: $password
                source: $source
                companies: $companies
            ) {
                userId
            }
        }
    `;

export const getCreateUserVariables = (data, tenantId) => {
    return {
        userLogin: data.email,
        password: data.firstName + data.lastName,
        source: "Backoffice",
        companies: [
            {
                tenantId,
                userId: 1,
                isActive: data.status,
                basicInformation: {
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    fullName: data.firstName + " " + data.lastName,
                    identification: data.identification,
                    phone: data.cellPhone,
                    idType: 18, // TODO: Change this to a dynamic value
                },
                systemParameters: {
                    userLicenseType: data.role,
                    isAdministrator: data.isAdmin,
                    licenseType: 0,
                },
            }
        ]
    }
}