import React, { Fragment, useState } from 'react';
import config from '../../config';
import ModalComplements from "./modalComplement";
import axios from "axios";
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import "./modalComplement/modal.scss";
import { getAddonComplements } from './utils';
import { getCookie } from "../../utils/cookies";
import { convert2AlphaCodeTo3AlphaCodeCountry } from "../../sagas/marketSaga";
import { uuidV4Generator } from "../../utils/randomizer";
import { useContext } from "react";
import CountryContext from "../../context/countries-context";
import { useAuth } from '../../context/auth-context';

const Complements = ({ companyInfo }) => {
  const countryContext = useContext(CountryContext);
  const [showModal, setshowModal] = useState(false);
  const [complementRequest, setComplementRequest] = useState("");
  const { checkAuth } = useAuth();

  const showModalComplements = async () => {
    try {
      if (!checkAuth()) return;
      setshowModal(true);
      let { data: addOns } = await getAddons();

      if (addOns.length && Array.isArray(addOns)) {
        addOns = getAddonComplements(addOns);
        return setComplementRequest(addOns);
      }

      return setComplementRequest([]);
    } catch (error) {
      return setComplementRequest([]);
    }
  }

  const getAddons = async () => {
    return axios({
      method: 'get',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getCookie('token')}`,
        'backoffice-service-country': convert2AlphaCodeTo3AlphaCodeCountry(countryContext.country),
        'backoffice-service-name': 'addons-service',
        'backoffice-request-id': uuidV4Generator(),
      },
      url: `${config.url}bridge?cloudTenantId=${companyInfo.tenantId}`,
    })
  }

  return (
    <Fragment>
      <SiigoButtonAtom
        className='right-buttons'
        color="secondary"
        text="Complementos"
        icon="fas fa-plug"
        onClick={showModalComplements}
      />
      {complementRequest &&
        <ModalComplements
          showModal={showModal}
          setshowModal={setshowModal}
          getAddons={getAddons}
          companyInfo={companyInfo}
          complementRequest={complementRequest}
        />
      }
    </Fragment>
  );
}
export default Complements;