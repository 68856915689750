import React, { useContext, useEffect, useState } from "react";
import ModalResponse from "../layout/modals/modalResponse";
import { ALLOWED_ACCOUNTANT_USERS, rolUsersCompanyEnum, rolUsersCompanyMap } from "./plandata";
import mailProvider from "../../providers/mailProvider/mailProvider";
import * as MarketActions from "../../actions/market/marketAction";
import * as UsersDomainActions from "../../actions/usersDomain/usersDomainAction";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { ALLOWED_ADMINISTRATOR_FOR_USER_ROLE, COUNTRYS } from "./utils";
import CountryContext from "../../context/countries-context";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { returnFormUserByCountry } from "./model/formUsersModel";
import { withTranslation } from "react-i18next";
import { StatusCodes } from 'http-status-codes';
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import config from '../../config';
import { uuidV4Generator } from "../../utils/randomizer";
import { getCookie } from "../../utils/cookies";
import { convert2AlphaCodeTo3AlphaCodeCountry } from "../../sagas/marketSaga"
import Loader from '../tools/loader';
import { SiigoButtonAtom } from "@siigo/siigo-button-atom-react";
import { SiigoTooltipAtom } from "@siigo/siigo-tooltip-atom-react";
import { SiigoButtonIconAtom } from "@siigo/siigo-button-icon-atom-react";
import ModalChangeUserEmail from "./modalChangeUserEmail";
import LoaderContext from '../../context/loader-context';
import { useAuth } from "../../context/auth-context";
import { getAccess } from '../../utils/access';
import { ACCESS } from './access';

const ACTIONS = {
  CREATE: "create",
  EDIT: "edit"
}

const FormUsers = ({ t, id, action, actions }) => {
  const marketState = useSelector((_state) => _state.market);
  const usersDomainState = useSelector((_state) => _state.usersDomain);
  const history = useHistory();
  const location = useLocation();
  const companyInfo = location.state?.company;
  const companyEdit = JSON.parse(sessionStorage.getItem("userEdit") || "{}");
  const [companyUser, setCompanyUser] = useState({
    identification: companyEdit.identification || "",
    firstName: companyEdit.firstName || "",
    lastName: companyEdit.lastName || "",
    email: companyEdit.userCode || "",
    cellPhone: companyEdit.cellPhone || "",
    role: companyEdit.role,
    status: typeof companyEdit.status === 'boolean' ? companyEdit.status : true,
    isAdmin: companyEdit.isAdmin === 1 ? true : false,
    cloudCompanyTenantID: companyInfo?.cloudTenantID,
    companyKey: companyInfo?.cloudTenantCompanyKey,
    id: companyEdit.id
  });
  const [openModalUserEmail, setOpenModalUserEmail] = useState(false);
  const [isResendMail, setResendMail] = useState();
  const [userHasMovements, setUserHasMovements] = useState(false);

  const countryContext = useContext(CountryContext);
  const loaderContext = useContext(LoaderContext);
  const { checkAuth } = useAuth();

  useEffect(() => {
    if (countryContext.isCountryChanged()) {
      countryContext.setCountryChanged(false);
      window.location.href = "/market";
    }
  }, [countryContext]);

  useEffect(() => {
    if (!countryContext.isCountryChanged()
      && countryContext.country === COUNTRYS.COLOMBIA.CODE
      && companyEdit?.id && companyInfo?.userNameKey) {
      const params = {
        UsersID: companyEdit?.id,
        UserNameKey: companyInfo?.userNameKey
      }
      actions?.getUserHasMovements(params);
    }
  }, [countryContext?.country]);

  useEffect(() => {
    if (!marketState?.isLoading) setUserHasMovements(marketState?.hasMovements)
  }, [marketState]);

  useEffect(() => {
    loaderContext?.showLoader(false);
    if (marketState?.users?.statusCode === StatusCodes.BAD_REQUEST
      || usersDomainState?.users?.statusCode === StatusCodes.BAD_REQUEST) {
      ModalResponse({
        title: action.params.action === ACTIONS.CREATE ? t("market.companyUsers.alerts.error.title") : t("market.companyUsers.alerts.editError.title"),
        text: action.params.action === ACTIONS.CREATE ? t("market.companyUsers.alerts.error.text") : t("market.companyUsers.alerts.editError.text"),
        res: StatusCodes.BAD_REQUEST,
      });
    }

    if (marketState?.users?.statusCode === StatusCodes.OK
      && (!marketState?.users?.data?.responseXml && !marketState?.users?.data?.additionalUserUpdate)) {
      ModalResponse({
        text: action.params.action === ACTIONS.CREATE ? t("market.companyUsers.alerts.error.text") : marketState?.users?.data?.message || t("market.companyUsers.alerts.editError.text"),
        res: StatusCodes.BAD_REQUEST,
      });
    }

    if ((marketState?.users?.statusCode === StatusCodes.OK
      && marketState?.users?.data?.responseXml && marketState?.users?.data?.additionalUserUpdate)
      || usersDomainState?.users?.statusCode === StatusCodes.OK) {
      ModalResponse({
        title: action.params.action === ACTIONS.CREATE ? t("market.companyUsers.alerts.success.title") : t("market.companyUsers.alerts.editSuccess.title"),
        text: action.params.action === ACTIONS.CREATE ? t("market.companyUsers.alerts.success.text") : t("market.companyUsers.alerts.editSuccess.text"),
        res: StatusCodes.OK,
      });

      setTimeout(() => {
        sessionStorage.removeItem("users");
        redirectToAdminUrl();
      }, 2000);
    }

  }, [marketState?.users, usersDomainState?.users]);

  const [renderUserRoles] = useState(() => {
    const currentUsers = JSON.parse(localStorage.getItem("users"));
    const roleCounts = currentUsers?.reduce((counts, user) => {
      if (user.role === rolUsersCompanyEnum.WITH_ACCESS && user.status) {
        counts.AccessTotals += 1;
      }
      if (user.role === rolUsersCompanyEnum.WITH_ACCESS_ACCOUNTANT && user.status) {
        counts.AccountantTotals += 1;
      }
      return counts;
    }, { AccessTotals: 0, AccountantTotals: 0 });

    return rolUsersCompanyMap.map((role, index) => {
      const isMexicoRestrictedRole = countryContext.country === COUNTRYS.MEXICO.CODE &&
        (role.id === rolUsersCompanyEnum.PAYROLL_MANAGER || role.id === rolUsersCompanyEnum.WITH_ACCESS_LITE);

      const isCreateAction = action.params.action === ACTIONS.CREATE;
      const isEditAction = action.params.action === ACTIONS.EDIT;

      const isAccessRoleExceedLimit = role.id === rolUsersCompanyEnum.WITH_ACCESS &&
        roleCounts?.AccessTotals >= companyInfo?.allowedUsers;
      const isAccountantRoleExceedLimit = role.id === rolUsersCompanyEnum.WITH_ACCESS_ACCOUNTANT &&
        roleCounts?.AccountantTotals >= ALLOWED_ACCOUNTANT_USERS;

      if (isMexicoRestrictedRole) {
        return null;
      }

      if (isCreateAction && (isAccessRoleExceedLimit || isAccountantRoleExceedLimit)) {
        return null;
      }

      if (isEditAction && (isAccessRoleExceedLimit || isAccountantRoleExceedLimit) && role.id !== companyUser.role) {
        return null;
      }

      return (
        <option key={index} value={role.id} className={`${role.id === 0 ? "successState" : "errorState"}`}>{role.name}</option>
      );
    });
  })

  const setClassInputError = (isTouched, field) => isTouched && field !== undefined && 'inputError';

  const restorePassword = async () => {

    if (!isResendMail) {
      if (!checkAuth()) return;
      setResendMail(true);

      let userEmail = companyUser?.email?.trim();
      let userFullName = companyUser?.firstName?.trim() + " " + companyUser?.lastName?.trim();
      let bodyResendMail = {
        userEmail,
        sendWelcomeMail: true,
        userFullName
      };
      const params = {
        bodyResendMail,
        meta: {
          country: countryContext.country,
          requestId: uuidV4Generator(),
        }
      }
      const options = {
        method: 'post',
        url: `${config.url}bridge?companyKey=${companyInfo?.cloudTenantCompanyKey}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('token')}`,
          'backoffice-service-country': convert2AlphaCodeTo3AlphaCodeCountry(params.meta.country),
          'backoffice-service-name': 'resend-email',
          'backoffice-request-id': params.meta.requestId
        },
        data: params.bodyResendMail
      }

      const pass = await mailProvider.resetPassword(userEmail, options);
      if (pass) {
        setResendMail(false);
        ModalResponse({
          title: "¡Envío exitoso!",
          text:
            "Se ha enviado un correo a " + userEmail + " con la nueva contraseña",
          res: 200,
        });
      } else {
        setResendMail(false);
        ModalResponse({
          title: "Error al enviar",
          text: "No se pudo restablecer contraseña, por favor intente mas tarde",
          res: 500,
        });
      }
    }
  }

  const renderChangeEmailComponent = () => (
    <>
      <div className="change-user-email">
        <SiigoButtonAtom
          text={t("market.companyUsers.buttons.changeUserEmail")}
          size="s"
          onClick={() => setOpenModalUserEmail(true)} />
      </div>
    </>
  );

  const renderTooltip = () => (
    <SiigoTooltipAtom
      label={t("market.companyUsers.form.tooltip.label")}>
      <SiigoButtonIconAtom
        icon="fa fa-question"
        color="secondary"
        size="xs" />
    </SiigoTooltipAtom>
  );

  const renderComponents = (values) => {
    let components = [];
    components.push(<ErrorMessage className="error-message" name="email" component="span" />);
    if (action.params.action === ACTIONS.EDIT) {
      if (!userHasMovements && values.role !== rolUsersCompanyEnum.WITH_ACCESS_ACCOUNTANT) components.push(renderChangeEmailComponent());

      if (userHasMovements) {
        components.push(renderTooltip());

        if (values.role !== rolUsersCompanyEnum.WITH_ACCESS_ACCOUNTANT && getAccess("/market", ACCESS.Modify_Mail_Special)) {
          components.push(renderChangeEmailComponent());
        }
      }
    }
    return components;
  };

  const validateUser = (user) => {
    const currentUsers = JSON.parse(localStorage.getItem("users"));
    const userExistEmail = currentUsers?.find((currentUser) => currentUser.email === user.email);
    const userExistIdentification = currentUsers?.find((currentUser) => currentUser.identification === user.identification);
    if (userExistEmail) return { invalid: true, message: t("market.companyUsers.validations.userAlreadyExistsReason1") };
    if (userExistIdentification) return { invalid: true, message: t("market.companyUsers.validations.userAlreadyExistsReason2") };
    return { invalid: false };
  }

  const validateAtLeastOneAdmin = (user) => {
    const validAdmin = {
      invalid: false
    };
    const currentUsers = JSON.parse(localStorage.getItem("users"));
    const existingAdmins = currentUsers?.filter((user) => user.status &&
      Boolean(user.isAdmin) && user.role === rolUsersCompanyEnum.WITH_ACCESS
    );
    const previousUserInfo = currentUsers?.find((savedUser) => savedUser?.id === user.id);
    const currentUserIsAdminAndInactive = Boolean(previousUserInfo.isAdmin) &&
      user.role === previousUserInfo.role && !user.status;
    const currentUserIsLastAdmin = (existingAdmins?.length === 1) && existingAdmins[0].id === user.id;

    const isNotAbleToDisable = currentUserIsAdminAndInactive && currentUserIsLastAdmin;
    validAdmin.invalid = isNotAbleToDisable;

    if (isNotAbleToDisable)
      validAdmin.message = t("market.companyUsers.validations.atLeastOneAdmin")

    return validAdmin;
  }

  const validateUserLimit = (user) => {
    const validUser = {
      invalid: false
    };
    const currentUsers = JSON.parse(localStorage.getItem("users"));
    const previousUserInfo = currentUsers?.find((userSaved) => companyUser.id === userSaved.id);
    const roleCounts = currentUsers?.reduce((counts, user) => {
      if (user.role === rolUsersCompanyEnum.WITH_ACCESS && user.status && user.email !== companyUser.email) {
        counts.AccessTotals += 1;
      }
      if (user.role === rolUsersCompanyEnum.WITH_ACCESS_ACCOUNTANT && user.status && user.email !== companyUser.email) {
        counts.AccountantTotals += 1;
      }
      return counts;
    }, { AccessTotals: 0, AccountantTotals: 0 });

    const userChangeStatusAndIsActive = user.status && user.status !== previousUserInfo.status;

    const accountantUserExceedAllowed = user.role === rolUsersCompanyEnum.WITH_ACCESS_ACCOUNTANT &&
      (roleCounts?.AccountantTotals + Number(userChangeStatusAndIsActive)) > ALLOWED_ACCOUNTANT_USERS;
    const userExceedAllowed = user.role === rolUsersCompanyEnum.WITH_ACCESS &&
      (roleCounts?.AccessTotals + Number(userChangeStatusAndIsActive)) > companyInfo?.allowedUsers;

    const isNotAbleToEdit = userChangeStatusAndIsActive &&
      (accountantUserExceedAllowed || userExceedAllowed);
    validUser.invalid = isNotAbleToEdit;

    if (isNotAbleToEdit && companyUser.role === rolUsersCompanyEnum.WITH_ACCESS)
      validUser.message = "Has excedido el límite de usuarios con acceso activos";
    if (isNotAbleToEdit && companyUser.role === rolUsersCompanyEnum.WITH_ACCESS_ACCOUNTANT)
      validUser.message = "Has excedido el límite de usuarios contadores activos";
    return validUser;
  }

  const handleFormSubmit = async (validateForm, handleSubmit, setSubmitting, setFieldTouched) => {
    const errors = await validateForm();
    if (Object.keys(errors).length) {
      setSubmitting(false);
      Object.keys(errors).forEach((field) => setFieldTouched(field, true));
      ModalResponse({
        title: action.params.action === ACTIONS.CREATE ? t("market.companyUsers.alerts.formWithErrors.title") : t("market.companyUsers.alerts.formEditWithErrors.title"),
        text: action.params.action === ACTIONS.CREATE ? t("market.companyUsers.alerts.formWithErrors.text") : t("market.companyUsers.alerts.formEditWithErrors.text"),
        res: StatusCodes.BAD_REQUEST,
      });
    } else {
      handleSubmit();
    }
  };

  const onSubmit = async (values) => {
    if (action.params.action === ACTIONS.CREATE) {
      const userValidation = validateUser(values);
      if (userValidation.invalid && action.params.action === ACTIONS.CREATE) {
        return ModalResponse({ title: t("market.companyUsers.validations.userAlreadyExists"), text: userValidation.message, res: StatusCodes.BAD_REQUEST });
      }
      if (countryContext.country === COUNTRYS.COLOMBIA.CODE) {
        if (!checkAuth()) return;
        actions?.createUsersCompanyMarket({ ...values, isAdmin: values.isAdmin ? 1 : 0, userCode: values.email });
      }
      if (countryContext.country === COUNTRYS.MEXICO.CODE) {
        if (!checkAuth()) return;
        actions?.createUserUsersDomain({
          tenantId: companyInfo?.cloudTenantConnectionUser.split("SIIGOCloudTenant_")[1],
          ...values
        });
      }
    }

    if (action.params.action === ACTIONS.EDIT) {
      const atLeastOneAdminValidation = validateAtLeastOneAdmin(values);
      if (atLeastOneAdminValidation.invalid) {
        return ModalResponse({ text: atLeastOneAdminValidation.message, res: StatusCodes.BAD_REQUEST });
      }
      const userLimitValidation = validateUserLimit(values);
      if (userLimitValidation.invalid) {
        return ModalResponse({ text: userLimitValidation.message, res: StatusCodes.BAD_REQUEST });
      }
      if (countryContext.country === COUNTRYS.COLOMBIA.CODE) {
        if (!checkAuth()) return;
        actions?.editUsersCompanyMarket({
          ...values,
          isAdmin: values.isAdmin ? 1 : 0,
          userCode: companyUser.email,
          usernameKey: values.companyKey,
          CloudTenantID: values.cloudCompanyTenantID
        });
      }
      if (countryContext.country === COUNTRYS.MEXICO.CODE) {
        if (!checkAuth()) return;
        actions?.updateUserUsersDomain({
          tenantId: companyInfo?.cloudTenantConnectionUser.split("SIIGOCloudTenant_")[1],
          ...values
        });
      }
    }
    loaderContext?.showLoader(true, t("market.loadingText"));
  };

  const haveSpecificPropertiesChanged = (prevStateObject, newStateObj) => {
    const keys = new Set([...Object.keys(newStateObj), ...Object.keys(prevStateObject)]);

    for (const key of keys)
      if (newStateObj[key] !== prevStateObject[key]) return true;

    return false;
  };

  const redirectToAdminUrl = () => {
    history.push({
      pathname: `/users_management/${id}`,
      state: {
        from: location.state?.from,
        detail: location.state?.detail,
        company: location.state?.company,
        redirectParams: location.state?.redirectParams
      }
    });
  };

  const handleBackClick = (currentValues) => (e) => {
    e.preventDefault();

    const shouldRedirect = async () => {
      let redirect = false;
      if (action.params.action === ACTIONS.CREATE) redirect = true;
      if (action.params.action === ACTIONS.EDIT) {
        if (haveSpecificPropertiesChanged(companyUser, currentValues)) {
          return Swal.fire({
            customClass: { confirmButton: 'button green', cancelButton: 'button red' },
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Sí",
            cancelButtonText: "No",
            reverseButtons: true,
            title: t("market.companyUsers.alerts.changeToSave.title"),
            text: t("market.companyUsers.alerts.changeToSave.text"),
          }).then(result => result.isConfirmed);
        }
        redirect = true;
      }
      return Promise.resolve(redirect);
    };

    shouldRedirect().then(redirect => {
      if (redirect) redirectToAdminUrl();
    });
  }


  return (
    <Formik
      initialValues={companyUser}
      validationSchema={returnFormUserByCountry(countryContext?.country, t)}
      onSubmit={onSubmit}
    >
      {({ errors, touched, values, isSubmitting, setFieldValue, validateForm, handleSubmit, setSubmitting, setFieldTouched }) => (
        <>
          <Form onSubmit={(e) => {
            e.preventDefault();
            handleFormSubmit(validateForm, handleSubmit, setSubmitting, setFieldTouched);
          }}>
            <div className="uxDetailDataFilterTitle" style={{ textAlign: "left" }}>
              <h3>
                <a href="#" onClick={handleBackClick(values)}><i className="siicon icon-atras"></i>{" Atrás |"}</a>{" "}
                {action.params.action === "create" ? t("market.companyUsers.texts.creationTitle") : t("market.companyUsers.texts.editTitle") + " [" + companyUser.email + "]"}{" "}
              </h3>
              <button type="submit" className="a-button green-fill" disabled={isSubmitting}>{t("market.companyUsers.buttons.save")}</button>
              {action.params.action === "create" && (
                <button
                  type="button"
                  className="a-button red-button"
                  style={{ marginRight: "5px" }}
                  onClick={redirectToAdminUrl}
                >
                  {t("market.companyUsers.buttons.cancel")}
                </button>
              )}
            </div>

            <div className="grid-user-edit">
              <div className="row">
                <div className="column4">
                  <div className="input-container">
                    <label className="labelOk">{t("market.companyUsers.form.placeHolderFirstName")}</label>
                    <Field placeholder={t("market.companyUsers.form.placeHolderFirstName")} type="text" name="firstName" className={`${setClassInputError(touched?.firstName, errors?.firstName)}`} />
                    <ErrorMessage className="error-message" name="firstName" component="span" />
                  </div>
                </div>

                <div className="column4">
                  <div className="input-container">
                    <label className="labelOk">{t("market.companyUsers.form.placeHolderLastName")}</label>
                    <Field placeholder={t("market.companyUsers.form.placeHolderLastName")} type="text" name="lastName" className={`${setClassInputError(touched?.lastName, errors?.lastName)}`} />
                    <ErrorMessage className="error-message" name="lastName" component="span" />
                  </div>
                </div>

                <div className="column4">
                  <div className="input-container">
                    <label className="labelOk">{t("market.companyUsers.form.placeHolderEmail")}</label>
                    <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                      <Field
                        type="text" name="email"
                        placeholder={t("market.companyUsers.form.placeHolderEmail")}
                        className={`${setClassInputError(touched?.email, errors?.email)}`}
                        disabled={action.params.action === ACTIONS.EDIT}
                      />
                      {renderComponents(values)}
                    </div>
                  </div>
                </div>

                <div className="column4">
                  <div className="input-container">
                    {action.params.action === "create" ? (
                      <div>
                        <label style={{ color: "grey" }} className="labelOk">{t("market.companyUsers.form.placeHolderResetPassword")}</label>
                        <a href="javascript:;" alt={t("market.companyUsers.form.placeHolderResetPassword")} style={{ color: "grey", textDecoration: " underline" }} className="link-password">{t("market.companyUsers.form.placeHolderSendNewPassword")}</a>
                      </div>
                    ) : (
                      <div>
                        <label className="labelOk">{t("market.companyUsers.form.placeHolderResetPassword")}</label>
                        {(isResendMail ? <Loader className="loader" /> : <a href="javascriat:;" onClick={() => restorePassword()}
                          alt={t("market.companyUsers.form.placeHolderResetPassword")}
                          className="link-password">{t("market.companyUsers.form.placeHolderSendNewPassword")}
                        </a>)}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="column4">
                  <div className="input-container">
                    <label className="labelOk">{t("market.companyUsers.form.placeHolderIdentification")}</label>
                    <Field placeholder={t("market.companyUsers.form.placeHolderIdentification")} type="text" name="identification" className={`${setClassInputError(touched?.identification, errors?.identification)}`} />
                    <ErrorMessage className="error-message" name="identification" component="span" />
                  </div>
                </div>

                <div className="column4">
                  <div className="input-container">
                    <label className="labelOk">{t("market.companyUsers.form.placeHolderCellphone")}</label>
                    <Field placeholder={t("market.companyUsers.form.placeHolderCellphone")} type="text" name="cellPhone" className={`${setClassInputError(touched?.cellPhone, errors?.cellPhone)}`} />
                    <ErrorMessage className="error-message" name="cellPhone" component="span" />
                  </div>
                </div>

                <div className="column4">
                  <div className="input-container">
                    <label className="labelOk">{t("market.companyUsers.form.placeHolderRole")}</label>
                    <Field as="select" name="role" className={`border-select ${setClassInputError(touched?.role, errors?.role)}`} onChange={(_event) => {
                      const { value } = _event.target;
                      setFieldValue('role', parseInt(value))
                      setFieldValue('isAdmin', false)
                    }}>
                      <option value="-9">Seleciona una opción</option>
                      {renderUserRoles}
                    </Field>
                    <ErrorMessage className="error-message" name="role" component="span" />
                  </div>
                  {ALLOWED_ADMINISTRATOR_FOR_USER_ROLE.includes(values.role) && (
                    <div className="ckeckcontainer">
                      <label className="labelOk labelcheckadmin" htmlFor="isAdmin">{t("market.companyUsers.form.placeHolderIsAdmin")}</label>
                      <Field type="checkbox" name="isAdmin" className="ckTable checkadmin" />
                    </div>
                  )}
                </div>

                <div className="column4">
                  <div className="input-container">
                    <label className="labelOk">{t("market.companyUsers.form.placeHolderStatus")}</label>
                    <Field as="select" name="status" className={`border-select ${setClassInputError(touched?.status, errors?.status)}`} onChange={(_event) => {
                      const { value } = _event.target;
                      setFieldValue('status', value === 'true' ? true : false)
                    }}>
                      <option value={true}>{t("market.companyUsers.form.placeHolderStatusActive")}</option>
                      {action.params.action === ACTIONS.EDIT && (
                        <option value={false}>{t("market.companyUsers.form.placeHolderStatusInactive")}</option>
                      )}
                    </Field>
                    <ErrorMessage className="error-message" name="status" component="span" />
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <ModalChangeUserEmail
            country={countryContext.country}
            showModal={openModalUserEmail}
            handleCloseModal={() => setOpenModalUserEmail(false)}
            company={companyInfo}
            user={companyUser}
            setFieldValue={setFieldValue}
            setCompanyUser={setCompanyUser}
          />
        </>
      )
      }
    </Formik >
  );
}

const mapStateToProps = (state) => ({
  error: (state?.market?.err) ? state.market.err : state.usersDomain.err,
  isLoading: (state?.market?.isLoading) ? state.market.isLoading : state.usersDomain.isLoading,
  users: (state?.market?.users) ? state.market.users : state.usersDomain.users,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...MarketActions, ...UsersDomainActions }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(FormUsers)));
