import { put, call } from "redux-saga/effects";
import { request } from "graphql-request";
import * as types from "../actions";
import { createUserMutation, getCreateUserVariables } from "../services/graphql/mutations/createUser";
import { getCookie } from "../utils/cookies";
import { getUpdateUserVariables, updateUserMutation } from "../services/graphql/mutations/updateUser";
import { getAllUsersQuery } from "../services/graphql/queries/getAllUsers";
import { formatUserResponse } from "../utils/formatUsersResponse";
import { deleteUserMutation, getDeleteUserVariables } from "../services/graphql/mutations/deleteUser";
import getEndPoint from "../utils/endpoints";
import { jwtDecode } from "jwt-decode";
import { StatusCodes } from "http-status-codes";

const ACTIONS = {
    CREATE: "create",
    EDIT: "edit",
    DELETE: "delete"
}

export function* getUsersUsersDomain(data) {
    const tenantId = data?.params;
    const USERS_DOMAIN_URL = getEndPoint("provisioningHandler");

    try {
        const variables = { tenantId: tenantId };
        const token = jwtDecode(getCookie('token')).access_token_nube;
        const headers = { authorization: token }
        const response = yield call(
            request, USERS_DOMAIN_URL,
            getAllUsersQuery,
            variables, headers
        );

        const users = formatUserResponse(response?.GetUser.users);

        yield put({
            type: types.GET_USERS_USERS_DOMAIN_RECEIVED,
            data: users.length && Array.isArray(users) ? users : [],
        });

    } catch (err) {
        yield put({ type: types.GET_USERS_USERS_DOMAIN_FAILED, err });
    }
}

export function* createUserUsersDomain(data) {
    const tenantId = data?.params.tenantId;
    delete (data?.params.tenantId);
    const userNew = data?.params;
    const USERS_DOMAIN_URL = getEndPoint("provisioningHandler");

    try {
        const token = jwtDecode(getCookie('token')).access_token_nube;
        const variables = getCreateUserVariables(userNew, tenantId);
        const headers = { authorization: token }
        const response = yield call(
            request, USERS_DOMAIN_URL,
            createUserMutation,
            variables, headers
        );

        yield put({ type: types.CREATE_USER_USERS_DOMAIN_SUCCESS, data: { statusCode: StatusCodes.OK, data: response, action: ACTIONS.CREATE } });
    } catch (err) {
        if (err.response.status === StatusCodes.OK && err.response.errors) {
            yield put({ type: types.CREATE_USER_USERS_DOMAIN_SUCCESS, data: { statusCode: StatusCodes.BAD_REQUEST, data: null } });
        } else {
            yield put({ type: types.CREATE_USER_USERS_DOMAIN_FAILED, data: err });
        }
    }
}

export function* updateUserUsersDomain(data) {
    const tenantId = data?.params.tenantId;
    delete (data?.params.tenantId);
    const userNew = data?.params;
    const USERS_DOMAIN_URL = getEndPoint("provisioningHandler");

    try {
        const token = jwtDecode(getCookie('token')).access_token_nube;
        const variables = getUpdateUserVariables(userNew, tenantId);
        const headers = { authorization: token }
        const response = yield call(
            request, USERS_DOMAIN_URL,
            updateUserMutation,
            variables, headers
        );

        yield put({
            type: types.UPDATE_USER_USERS_DOMAIN_SUCCESS, data: {
                statusCode: StatusCodes.OK, data: response, action: ACTIONS.EDIT
            }
        });
    } catch (err) {
        if (err.response.status === StatusCodes.OK && err.response.errors) {
            yield put({
                type: types.UPDATE_USER_USERS_DOMAIN_SUCCESS, data: {
                    statusCode: StatusCodes.BAD_REQUEST, data: null
                }
            });
        }
        yield put({ type: types.UPDATE_USER_USERS_DOMAIN_FAILED, err });
    }
}

export function* deleteUserUsersDomain(data) {
    const userDelete = data?.params.item;
    const USERS_DOMAIN_URL = getEndPoint("provisioningHandler");

    try {
        const token = jwtDecode(getCookie('token')).access_token_nube;
        const variables = getDeleteUserVariables(userDelete);
        const headers = { authorization: token }
        const response = yield call(
            request, USERS_DOMAIN_URL,
            deleteUserMutation,
            variables, headers
        );
        yield put({
            type: types.DELETE_USER_USERS_DOMAIN_SUCCESS,
            data: {
                statusCode: StatusCodes.OK, data: response, action: ACTIONS.DELETE
            }
        });
    } catch (err) {
        yield put({ type: types.DELETE_USER_USERS_DOMAIN_FAILED, err });
    }
}