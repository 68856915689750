import React, { Fragment, useContext, useEffect } from "react";
import { rolUsersCompany, rolUsersCompanyEnum } from "./plandata";
import { SiigoDataTableMolecule } from "@siigo/siigo-data-table-molecule-react";
import * as MarketActions from "../../actions/market/marketAction";
import * as UsersDomainActions from "../../actions/usersDomain/usersDomainAction";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import ModalResponse from "../layout/modals/modalResponse";
import { connect, useSelector } from "react-redux";
import { getAccess } from "./../../utils/access";
import { ACCESS } from "./access";
import ModalDelete from '../layout/modals/modalDelete';
import { COUNTRYS } from "./utils";
import CountryContext from "../../context/countries-context";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PropTypes from 'prop-types';
import { uuidV4Generator } from "../../utils/randomizer";
import { useTranslation } from "react-i18next";
import { StatusCodes } from "http-status-codes";
import LoaderContext from "../../context/loader-context";
import { useAuth } from "../../context/auth-context";

const ACTIONS = {
  DELETE: "delete"
};

const arrayData = (users = []) => {
  let usersArray = Array.isArray(users) ? users : (users?.data ? [users.data] : []);
  usersArray = usersArray?.map((user) => {
    user.roleName = rolUsersCompany[user.role] || "NA";
    user.isAdministrator = user.isAdmin === 1 ? "Sí" : "No";
    user.statusText = user.status ? "Activo" : "Inactivo";
    return user;
  });
  sessionStorage.setItem("users", JSON.stringify(users));
  return users;
};

const UsersManagement = ({
  id,
  users,
  isLoading,
  actions
}) => {
  const marketState = useSelector((_state) => _state.market);
  const usersDomainState = useSelector((_state) => _state.usersDomain);
  const history = useHistory();
  const location = useLocation();
  const countryContext = useContext(CountryContext);
  const loaderContext = useContext(LoaderContext);
  const { t } = useTranslation();
  const { checkAuth } = useAuth();
  const companyInfo = location?.state?.company;

  useEffect(() => {
    getCompanyUsers();
  }, [countryContext?.country]);

  useEffect(() => {
    loaderContext?.showLoader(false);
    if (marketState?.users?.action === ACTIONS.DELETE
      || usersDomainState?.users?.action === ACTIONS.DELETE) {

      if (marketState?.users?.error?.status === StatusCodes.CONFLICT) {
        ModalResponse({
          text: t("market.companyUsers.alerts.deleteError.hasMovements"),
          res: StatusCodes.CONFLICT,
        });
      }

      if (marketState?.users?.error?.status === StatusCodes.BAD_REQUEST
        || usersDomainState?.users?.error?.status === StatusCodes.BAD_REQUEST) {
        ModalResponse({
          title: t("market.companyUsers.alerts.deleteError.title"),
          text: t("market.companyUsers.alerts.deleteError.text"),
          res: StatusCodes.BAD_REQUEST,
        });
      }

      if (marketState?.users?.statusCode === StatusCodes.OK
        || usersDomainState?.users?.statusCode === StatusCodes.OK) {
        ModalResponse({
          title: t("market.companyUsers.alerts.deleteSuccess.title"),
          text: t("market.companyUsers.alerts.deleteSuccess.text"),
          res: StatusCodes.OK,
        });
      }

      if (!users?.length) getCompanyUsers();
    }
  }, [
    marketState?.users, usersDomainState?.users,
    marketState?.action, usersDomainState?.action
  ]);

  useEffect(() => {
    if (users?.length) localStorage.setItem("users", JSON.stringify(users));
  }, [users]);

  useEffect(() => {
    if (countryContext?.isCountryChanged()) {
      countryContext.setCountryChanged(false);
      window.location.href = "/market";
    }
  }, [countryContext]);

  useEffect(() => {
    initDataTable();
  }, [users, companyInfo]);

  const getCompanyUsers = () => {
    switch (countryContext?.country) {
      case COUNTRYS.MEXICO.CODE:
        actions.getUsersUsersDomain(companyInfo?.cloudTenantConnectionUser.split("_")[1])
        break;
      case COUNTRYS.COLOMBIA.CODE:
        actions.getUsersCompanyMarket({
          data: id,
          meta: {
            country: countryContext.country,
            requestId: uuidV4Generator(),
          }
        })
        break;
      default:
        break;
    }
  }

  const initDataTable = () => {
    const table = document.getElementsByTagName("siigo-data-table-molecule")[0];
    table?.setDefaultTemplate({
      actions: (value, args) => {
        return getActionEdit(value, args);
      },
    });
  };

  const createUserAction = (event) => {
    event.preventDefault();
    sessionStorage.removeItem("userEdit");
    const adminUrl = history.location.pathname;
    sessionStorage.removeItem("userEdit");
    history.push({
      pathname: `/company/${id}/user/create`,
      state: {
        from: location.state?.from,
        detail: location.state?.detail,
        company: companyInfo,
        adminUrl: adminUrl,
        redirectParams: location.state?.redirectParams
      }
    })
  }

  const getActionEdit = (value, args) => {
    // Create elements html
    const buttonEdit = document.createElement("siigo-button-atom");
    const buttonDelete = document.createElement("siigo-button-atom");

    const divContainerActions = document.createElement("div");


    // Boton eliminar
    if (getAccess("/market", ACCESS.Users_Management_delete)) {
      buttonDelete.text = "Eliminar";
      buttonDelete.color = "primary-red";
      buttonDelete.size = "m";
      buttonDelete.addEventListener("click", function (e) {
        //Validacion para que no elimine un administrador
        const usersData = JSON.parse(sessionStorage.getItem("users"));
        if (args.item.role === rolUsersCompanyEnum.WITH_ACCESS && args.item.isAdmin === 1) {
          const existsOtherAdmins = usersData.some((currentUser) => currentUser.isAdmin === 1 && currentUser.role === rolUsersCompanyEnum.WITH_ACCESS && currentUser.userCode !== args.item.userCode && currentUser.status);
          if (!existsOtherAdmins) {
            return ModalResponse({
              text: t("market.companyUsers.validations.atLeastOneAdmin"),
              res: StatusCodes.INTERNAL_SERVER_ERROR,
            });
          }
        }

        if (!checkAuth()) return;
        const actionToDelete = (id) => {
          loaderContext?.showLoader(true, t("market.loadingText"));
          if (countryContext?.country === COUNTRYS.COLOMBIA.CODE)
            actions.deleteUsersCompanyMarket(id)
          else
            actions.deleteUserUsersDomain(id)
        }
        args.id = companyInfo.userNameKey;
        args.CloudTenantID = companyInfo.cloudTenantID;
        ModalDelete({
          actionDelete: actionToDelete,
          id: args,
          dataText: { title: 'Eliminación', text: "¿Confirmas que el usuario que deseas eliminar es " + args.item.userCode + "?" }
        })
      })
      divContainerActions.appendChild(buttonDelete);
    }

    if (getAccess("/market", ACCESS.Users_Management_edit)) {
      // Boton Editar
      buttonEdit.text = "Editar";
      buttonEdit.color = "primary-green";
      buttonEdit.size = "m";
      buttonEdit.addEventListener("click", function (e) {
        loaderContext?.showLoader(true, t("market.loadingText"));
        sessionStorage.setItem("userEdit", JSON.stringify(args.item));
        const adminUrl = history.location.pathname;
        history.push({
          pathname: `/company/${id}/user/edit`,
          state: {
            from: location.state?.from,
            detail: location.state?.detail,
            company: companyInfo,
            adminUrl: adminUrl,
            redirectParams: location.state?.redirectParams
          }
        })
      });
      divContainerActions.appendChild(buttonEdit);
    }

    //Estilos del div de los botones
    divContainerActions.style.display = "flex";
    divContainerActions.style.width = "100%";
    divContainerActions.style.justifyContent = "space-around";

    return divContainerActions;
  };

  const handleBackClick = () => {
    const previusUrl = location.state?.detail;
    history.push({
      pathname: previusUrl,
      state: {
        from: location.state?.from,
        company: companyInfo,
        redirectParams: location.state?.redirectParams
      }
    });
  };

  return (
    <Fragment>
      <div
        className="uxDetailDataFilterTitle flex-init"
        style={{ textAlign: "left", marginBottom: "30px" }}
      >
        <div className='header-company-detail sub-title'>
          <div className="sub-title">
            <a className="link-back" onClick={handleBackClick}>
              <i className="siicon icon-atras"></i>
              <span>{" Atrás | Usuarios " + companyInfo?.companyKey}</span>
            </a>
          </div>
        </div>
        {getAccess("/market", ACCESS.Users_Management_create) &&
          <div className="button-create">
            <siigo-button-atom
              color="primary-green"
              onClick={createUserAction}
              name="create"
              size="m"
              text="Crear usuario"
              type="button"
            ></siigo-button-atom>
          </div>
        }
      </div>

      <SiigoDataTableMolecule
        onInit={initDataTable}
        select-rows={false}
        reorder={false}
        headers={[
          {
            text: "Correo de acceso",
            key: "userCode",
            iconType: "",
            type: "string",
            sortable: true,
            align: "center",
          },
          {
            text: "Estado",
            key: "statusText",
            iconType: "",
            type: "string",
            sortable: true,
            align: "center",
          },
          {
            text: "Es administrador",
            key: "isAdministrator",
            iconType: "",
            type: "string",
            sortable: true,
            align: "center",
          },
          {
            text: "Tipo de usuario",
            key: "roleName",
            iconType: "",
            type: "string",
            sortable: true,
            align: "center",
          },
          {
            text: "Acciones",
            key: "actions",
            iconType: "",
            type: "string",
            align: "center",
          },
        ]}
        data={
          (!isLoading && users && arrayData(users)) || []
        }
      />
    </Fragment >
  );
};

UsersManagement.propTypes = {
  id: PropTypes.string.isRequired,
  actions: PropTypes.object
}

const mapStateToProps = (state) => ({
  error: (state?.market?.err) ? state.market.err : state.usersDomain.err,
  isLoading: (state?.market?.isLoading) ? state.market.isLoading : state.usersDomain.isLoading,
  users: (state?.market?.users) ? state.market.users : state.usersDomain.users,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...MarketActions, ...UsersDomainActions }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UsersManagement));
