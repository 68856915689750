import { SiigoSelectAtom } from "@siigo/siigo-select-atom-react";
import { SiigoButtonAtom } from "@siigo/siigo-button-atom-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Complementos, optionState,
    PlanesNominaNube,
    COMPLEMENTS_TYPES_ENUM,
    COMPLEMENTS_TYPES,
    PLANTYPE_FE
} from "../../plandata";
import { Cashiers } from "../cashiers/cashiers";
import { VALID_POS_WEB_CASHIERS, cashiersLimitExceeded } from "../../utils";
import ModalResponse from "../../../layout/modals/modalResponse";

export const EditPayrollComplementCard = ({ editComplementService, complements, compEdit, companyInfo }) => {
    const idNubeAddOns = 21;
    const [complementsList, setComplementsList] = useState([]);
    const [payrollComplementSelected, setPayrollComplementSelected] = useState("");
    const [nominaNube, setNominaNube] = useState(-1);
    const [payrollComplementStatus, setPayrollComplementStatus] = useState(complements.find(comp => comp.type === compEdit.valueParent)?.subState);
    const [isPosWeb, setIsPosWeb] = useState(false);
    const [form, setForm] = useState({ additionalCashiers: compEdit?.posActiveCashiers?.aditionalCashiers, baseCashiers: compEdit?.posActiveCashiers?.baseCashiers });
    const [isFormError, setIsFormError] = useState(false);
    const [isFeWhatsApp, setIsFeWhatsApp] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        setComplementsList(getPlanesComplements());
        setPayrollComplementSelected(getComplementInfoForSelect());
    }, []);

    const complementsFilter = (comp) => {
        return comp.valueSelected === compEdit.valueSelected && comp.documentBase === compEdit.documentBase;
    }
    const getComplementInfoForSelect = () => {
        return getPlanesComplements().filter(complementsFilter)[0];
    }

    const getPlanesComplements = () => {
        const allowedPlans = [PlanesNominaNube['Nuevo Siigo Nube'], PlanesNominaNube['Solo Nómina'], PlanesNominaNube['Siigo Nube Emprendedor'], PlanesNominaNube['Siigo Nube Independiente'], PlanesNominaNube['Siigo Nube Premium']];

        if (compEdit.valueParent === COMPLEMENTS_TYPES_ENUM.POW_WEB) {
            setIsPosWeb(true);
            const posWebComplements = Complementos.map((comp) => comp.planesComplemento)[1];
            if (companyInfo.planType === PLANTYPE_FE) return posWebComplements.filter(comp => comp.valueSelected >= compEdit.valueSelected);
            return posWebComplements.filter(comp => comp.value === compEdit.value && comp.documentBase === compEdit.documentBase);
        }

        if (compEdit.valueParent === COMPLEMENTS_TYPES_ENUM.FE_WHATSAPP) {
            setIsFeWhatsApp(true);
            return Complementos.map((comp) => comp.planesComplemento)[2];
        }

        if (!allowedPlans.includes(companyInfo.planType)) {
            return Complementos.map((comp) => comp.planesComplemento)[0].filter(
                comp => comp.id !== idNubeAddOns
            );
        }
        return Array.isArray(Complementos) && Complementos.map((comp) => comp.planesComplemento)[0];
    }

    const setDataSelected = (e) => {
        setPayrollComplementSelected(e.detail.selected)
        setNominaNube(e.detail.selected.id)
    }

    const setComplementStatus = (e) => {
        const value = e.detail?.selected?.value ?? 1;
        setPayrollComplementStatus(value)
    }

    const setEditComplementRequest = (addon, status, cloudCompanyKey, planType) => {
        return {
            name: COMPLEMENTS_TYPES[addon.valueParent],
            planName: addon.name,
            type: addon.valueParent,
            module: addon.valueSelected,
            documentBase: addon.documentBase,
            subState: status,
            companyKey: cloudCompanyKey,
            planType: planType,
            posActiveCashiers: { baseCashiers: form.baseCashiers || 1, aditionalCashiers: parseInt(form.additionalCashiers) || 0 }
        }
    }

    const editComplement = async () => {
        const body = setEditComplementRequest(
            payrollComplementSelected,
            payrollComplementStatus,
            companyInfo.companyKey,
            companyInfo.planType
        );

        if (body.type === COMPLEMENTS_TYPES_ENUM.POW_WEB) {
            const { exceeded, limit } = cashiersLimitExceeded(body.planType, body.module, body.posActiveCashiers.aditionalCashiers);
            if (exceeded) {
                ModalResponse({ text: t("cashiersLimit", { limit: limit }) })
                return;
            }
        }

        await editComplementService(companyInfo.tenantId, body)

        const modalSize = document.querySelector(".modal-complements .content");
        modalSize.style.height = "initial";
    }


    const handleValuesChange = (e) => {
        const { name, value } = e.target;
        setIsFormError(!VALID_POS_WEB_CASHIERS.test(value));
        setForm({ ...form, [name]: value });
    };

    return (
        <div className="container-add-complements">
            <>
                {!isFeWhatsApp &&
                    <>
                        <p>{t('editComplement')}</p>
                        <div className="input-add-components">
                            <SiigoSelectAtom
                                errorMessages="Error"
                                label={"Plan"}
                                onChangeData={(e) => setDataSelected(e)}
                                value={payrollComplementSelected.value}
                                options={complementsList}
                            />
                            {nominaNube === idNubeAddOns
                                &&
                                <p style={{ color: "orange", marginBottom: "10px" }}>{t("banerEmployeeQuantity")}</p>
                            }
                        </div>
                    </>
                }
                <p>{t('editStateComplement')}</p>
                <div className="input-add-components">
                    <SiigoSelectAtom
                        errorMessages="Error"
                        label="Estado"
                        onChangeData={setComplementStatus}
                        value={payrollComplementStatus}
                        options={optionState} />
                </div>
                {isPosWeb &&
                    <Cashiers
                        additionalCashiersValue={form.additionalCashiers}
                        baseCashiersValue={form.baseCashiers}
                        onValuesChange={handleValuesChange}
                    />
                }
                <div className="float-lf">
                    <SiigoButtonAtom
                        color="primary-blue"
                        iconPosition="start"
                        name="configButton"
                        size="m"
                        text={t('save')}
                        type="button"
                        onClick={() => editComplement()}
                        disabled={isFormError}
                    />
                </div>
            </>
        </div>
    );
}