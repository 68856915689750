import { put, call } from "redux-saga/effects";
import * as types from "../actions";
import API from "../services/api/indexMarket";
import APIHandler from "../services/api/indexMarketHandler";
import APILocal from "../services/api/index";
import APIGeneral from "../services/api/indexGeneral";
import config from "../config";
import { getCurrentDocumentBalanceUrl } from "../utils/getCurrentDocumentBalanceUrl"
import { getCookie } from "../utils/cookies";
import { uuidV4Generator } from "../utils/randomizer";

const USER_AUTH_KEY = "userdta";
const USER_AUTH_VALUE = config.userAuthValue;

const ACTIONS = {
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete"
}

export function convert2AlphaCodeTo3AlphaCodeCountry(country) {
  let countryAlpha3Code = '';
  switch (country.toLowerCase()) {
    case 'co':
      countryAlpha3Code = 'COL';
      break;
    case 'mx':
      countryAlpha3Code = 'MEX';
      break;
    case 'ec':
      countryAlpha3Code = 'ECU';
      break;
    default:
      countryAlpha3Code = '';
      break;
  }
  return countryAlpha3Code;

}

export function* getCompanies(action) {
  const api = API.create();


  try {
    const { params } = action;
    if (Object.keys(params.data).length) {
      api.setHeader('Authorization', `Bearer ${getCookie('token')}`);
      api.setHeader('backoffice-service-country', convert2AlphaCodeTo3AlphaCodeCountry(params.meta.country))
      api.setHeader('backoffice-service-name', 'get-companies')
      api.setHeader('backoffice-request-id', params.meta.requestId)
      let companies = yield call(api.market.companies, params.data);
      localStorage.setItem("paramsCompany", JSON.stringify(params.data));
      yield put({
        type: types.COMPANIES_RECEIVED,
        data: companies.data,
      });
    } else {
      yield put({
        type: types.COMPANIES_RECEIVED,
        data: {},
      });
    }
  } catch (err) {
    yield put({ type: types.COMPANIES_REQUEST_FAILED, err });
  }

}

export function* emailGetCompanies(action) {
  const api = API.create();
  try {
    const { params } = action;
    if (Object.keys(params.data).length) {
      api.setHeader('Authorization', `Bearer ${getCookie('token')}`);
      api.setHeader('backoffice-service-country', convert2AlphaCodeTo3AlphaCodeCountry(params.meta.country));
      api.setHeader('backoffice-service-name', 'email-get-companies');
      api.setHeader('backoffice-request-id', params.meta.requestId);
      let emailGetCompanies = yield call(api.market.emailGetCompanies, params.data.email);
      yield put({
        type: types.EMAIL_COPANIES_RECEIVED,
        data: emailGetCompanies.data,
      });
    } else {
      yield put({
        type: types.EMAIL_COPANIES_RECEIVED,
        data: {},
      });
    }
  } catch (err) {
    yield put({ type: types.EMAIL_COMPANIES_REQUEST_FAILED, err });
  }
}

export function* setPlan(action) {
  const { params } = action;
  const api = API.create();
  api.setHeader('Authorization', `Bearer ${getCookie('token')}`);
  api.setHeader('backoffice-service-country', convert2AlphaCodeTo3AlphaCodeCountry(params.meta.country))
  api.setHeader('backoffice-service-name', 'upgrade-company')
  api.setHeader('backoffice-request-id', params.meta.requestId)

  try {
    let plan = yield call(api.market.upgradeCompany, params.companyUpgrade);
    if (plan) {
      plan = true;
    }
    yield put({ type: types.PLAN_RECEIVED, data: plan });
  } catch (err) {
    yield put({ type: types.PLAN_REQUEST_FAILED, err });
  }
}

export function* createLog(action) {
  const api = APILocal.create();
  try {
    const { params } = action;
    const logDetail = yield call(api.logs.createLog, params); //.then(response => response.data);
    yield put({
      type: types.LOGS_RECEIVED,
      data: logDetail,
    });
  } catch (err) {
    yield put({ type: types.LOGS_REQUEST_FAILED, err });
  }
}

export function* getTask(action) {
  const api = API.create();

  try {
    api.setHeader("key", "Siigo000JarvisGFD");
    const { params } = action;
    let task = yield call(api.market.getTask, params);
    if (task) {
      api.removeHeader("key");
    }
    yield put({ type: types.TASK_RECEIVED, data: task });
  } catch (err) {
    yield put({ type: types.TASK_REQUEST_FAILED, err });
  }
}

export function* createCompany(action) {
  const api = API.create();
  const { params } = action;
  const { company, meta } = params

  api.setHeader('authorization', `Bearer ${getCookie('token')}`);
  api.setHeader('backoffice-service-country', convert2AlphaCodeTo3AlphaCodeCountry(meta.country))
  api.setHeader('backoffice-service-name', 'create-company')
  api.setHeader('backoffice-request-id', meta.requestId)

  try {
    if (company.quoteId === config.wifGenericCreateCompany && config.flagProductionEnvironment?.toLowerCase() === 'true') {
      api.removeHeader('backoffice-service-name');
      api.setHeader('backoffice-service-name', 'create-company-canary');
    }
    let response = yield call(api.market.createCompany, company);

    yield put({ type: types.COMPANY_RECEIVED, data: response });
  } catch (err) {
    yield put({ type: types.COMPANY_REQUEST_FAILED, err });
  }
}



export function* getAddOnsNE(action) {
  const api = API.create();
  try {
    api.setHeader(USER_AUTH_KEY, USER_AUTH_VALUE);
    let auth = yield call(api.market.auth);
    api.setHeader("auth", auth.data);
    const { params } = action;
    let addOns = yield call(api.market.getAddons, params);
    if (addOns?.data?.length) {
      api.removeHeader("auth");
    }
    yield put({ type: types.ADDONS_RECEIVED, data: addOns?.data?.length ? addOns?.data : [] });
  } catch (err) {
    yield put({ type: types.ADDONS_REQUEST_FAILED, err });
  }
}

export function* modifyCompany(action) {
  const api = API.create();
  const { params } = action;
  api.setHeader('authorization', `Bearer ${getCookie('token')}`);
  api.setHeader('backoffice-service-country', convert2AlphaCodeTo3AlphaCodeCountry(params.meta.country))
  api.setHeader('backoffice-service-name', 'update-company')
  api.setHeader('backoffice-request-id', params.meta.requestId)

  try {
    let modify = yield call(api.market.modifyCompany, params.modifyData);

    yield put({ type: types.MODIFY_COMPANY_RECEIVED, data: modify.status });
  } catch (err) {
    yield put({ type: types.MODIFY_COMPANY_FAILED, err });
  }
}

export function* getCompanyLogs(action) {
  const api = API.create();

  try {
    const { params } = action;
    const logs = yield call(api.market.getCompanyLogs, params);
    yield put({ type: types.GET_COMPANY_LOGS_RECEIVED, data: logs.data });
  } catch (err) {
    yield put({ type: types.GET_COMPANY_LOGS_FAILED, err });
  }
}

export function* changeCompanyAddonState(action) {
  const api = API.create();

  try {
    api.setHeader(USER_AUTH_KEY, USER_AUTH_VALUE);
    let auth = yield call(api.market.auth);
    api.setHeader("auth", auth.data);
    const { params } = action;

    let modify = yield call(api.market.changeCompanyAddonState, params);
    if (modify) {
      api.removeHeader("auth");
    }
    yield put({ type: types.CHANGE_COMPANY_ADDON_STATE_RECEIVED, data: modify.data });
  } catch (err) {
    yield put({ type: types.CHANGE_COMPANY_ADDON_STATE_FAILED, err });
  }
}

export function* getPackage(action) {

  const { url, auth } = getCurrentDocumentBalanceUrl()
  const api = APIGeneral.create(url);

  let instanceRequest = api.packages.packages
  if (config.flagNewUrlPackage === 'true') {
    api.setHeader('Authorization', auth.Authorization)
    instanceRequest = api.newPackages.packages
  }
  try {
    const { params } = action;
    let packages = yield call(instanceRequest, params);

    yield put({
      type: types.GET_PACKAGE_RECEIVED,
      data: packages.data === null ? [] : packages.data,
    });
  } catch (err) {
    yield put({ type: types.GET_PACKAGE_FAILED, err });
  }
}

export function* addPackage(action) {

  const { url, auth } = getCurrentDocumentBalanceUrl()

  const api = APIGeneral.create(url);
  let instanceRequest = api.packages.addPackage
  if (config.flagNewUrlPackage === 'true') {
    api.setHeader('Authorization', auth.Authorization)
    api.setHeader('name', getCookie('name'))
    api.setHeader('username', getCookie('email'))
    api.setHeader('backoffice-request-id', uuidV4Generator())
    instanceRequest = api.newPackages.addPackage
  }
  try {
    const { params } = action;
    let pack = yield call(instanceRequest, params);

    yield put({ type: types.ADD_PACKAGE_RECEIVED, data: pack.data });
  } catch (err) {
    yield put({ type: types.ADD_PACKAGE_FAILED, err });
  }
}

export function* deleteBalance(action) {

  const { url, auth } = getCurrentDocumentBalanceUrl()

  const api = APIGeneral.create(url);
  let instanceRequest = api.packages.deleteBalance

  if (config.flagNewUrlPackage === 'true') {
    api.setHeader('Authorization', auth.Authorization)
    instanceRequest = api.newPackages.deleteBalance
  }

  api.setHeader('name', getCookie('name'))
  api.setHeader('username', getCookie('email'))
  api.setHeader('backoffice-request-id', uuidV4Generator())

  try {
    const { params } = action;
    let packages = yield call(instanceRequest, params);
    yield put({ type: types.DELETE_BALANCE_RECEIVED, data: packages.data });
  } catch (err) {
    yield put({ type: types.DELETE_BALANCE_FAILED, err });
  }
}

export function* getBalanePack(action) {

  const { url, auth } = getCurrentDocumentBalanceUrl()
  const api = APIGeneral.create(url);
  let instanceRequest = api.packages.getBalance

  if (config.flagNewUrlPackage === 'true') {
    api.setHeader('Authorization', auth.Authorization)
    instanceRequest = api.newPackages.getBalance
  };
  try {
    const { params } = action;
    let packages = yield call(instanceRequest, params);
    yield put({
      type: types.GET_BALANCE_PACK_RECEIVED,
      data: packages.data && typeof packages.data === 'number' ? packages.data : '0',
    });
  } catch (err) {
    yield put({ type: types.GET_BALANCE_PACK_FAILED, err });
  }
}

export function* getPackageDetail(action) {

  const { url, auth } = getCurrentDocumentBalanceUrl()
  const api = APIGeneral.create(url);
  let instanceRequest = api.packages.getPurshage
  if (config.flagNewUrlPackage === 'true') {
    api.setHeader('Authorization', auth.Authorization)
    instanceRequest = api.newPackages.getPurshage
  }
  try {
    const { params } = action;
    let packages = yield call(instanceRequest, params);

    yield put({
      type: types.GET_PACKAGE_DETAIL_RECEIVED,
      data: packages.data === null ? [] : packages.data,
    });
  } catch (err) {
    yield put({ type: types.GET_PACKAGE_DETAIL_FAILED, err });
  }
}

export function* getUserHasMovements(action) {
  const api = APIHandler.create();
  const { params } = action;

  try {
    let { data } = yield call(api.marketHandler.userHasMovements, params);

    yield put({
      type: types.GET_USER_HAS_MOVEMENTS_SUCCESS,
      data: data.data
    });
  } catch (error) {
    yield put({ type: types.GET_USER_HAS_MOVEMENTS_FAILED, error });
  }
}

export function* getUsersCompanyMarket(action) {
  const api = API.create();
  const { params } = action;
  api.setHeader('authorization', `Bearer ${getCookie('token')}`);
  api.setHeader('backoffice-service-country', convert2AlphaCodeTo3AlphaCodeCountry(params.meta.country))
  api.setHeader('backoffice-service-name', 'company-users')
  api.setHeader('backoffice-request-id', params.meta.requestId)

  try {
    let users = yield call(api.market.getCompanyUsers, params.data);

    yield put({
      type: types.GET_USERS_COMPANY_MARKET_SUCCESS,
      data: users.data.length && Array.isArray(users.data) ? users.data : [],
    });
  } catch (err) {
    yield put({ type: types.GET_USERS_COMPANY_MARKET_FAILED, err });
  }
}

export function* createUsersCompanyMarket(data) {
  const userNew = data?.params;
  try {
    const api = APIHandler.create();

    let users = yield call(api.marketHandler.createUsers, userNew);

    yield put({
      type: types.GET_USERS_COMPANY_MARKET_SUCCESS,
      data: { ...users.data, action: ACTIONS.CREATE },
    });
  } catch (error) {
    yield put({
      type: types.GET_USERS_COMPANY_MARKET_FAILED,

      error: { action: ACTIONS.CREATE, error }
    });
  }
}

export function* editUsersCompanyMarket(data) {
  const userNew = data?.params;
  try {
    //Logica para editar un usuario
    const api = APIHandler.create();

    let response = yield call(api.marketHandler.editUsers, userNew);
    yield put({
      type: types.EDIT_USER_COMPANY_MARKET_SUCCESS,
      data: { ...response.data, action: ACTIONS.EDIT },
    });
  } catch (error) {
    yield put({
      type: types.EDIT_USER_COMPANY_MARKET_FAILED,
      error: { action: ACTIONS.EDIT, error }
    });
  }
}

export function* deleteUsersCompanyMarket(user) {
  try {
    const { CloudTenantID, item, id } = user.params;
    const params = {
      id,
      usersId: item.id,
      userCode: item.userCode,
      CloudTenantID
    }
    const api = APIHandler.create();
    let users = yield call(api.marketHandler.deleteUsers, params);
    yield put({
      type: types.DELETE_USER_COMPANY_MARKET_SUCCESS,
      data: { ...users.data, action: ACTIONS.DELETE },
    });
  } catch (error) {
    yield put({
      type: types.DELETE_USER_COMPANY_MARKET_FAILED,

      error: { action: ACTIONS.DELETE, error }
    });
  }
}

export function* addPayrollPackage(action) {

  const api = API.create();

  try {
    api.setHeader(USER_AUTH_KEY, USER_AUTH_VALUE);
    let auth = yield call(api.market.auth);
    api.setHeader("auth", auth.data);
    api.setHeader('name', getCookie('name'));
    api.setHeader('username', getCookie('email'));
    api.setHeader('backoffice-request-id', uuidV4Generator());
    const { params } = action;
    let payroll = yield call(api.payrollPackage.addPayrollPackage, params);
    yield put({ type: types.ADD_PAYROLL_PACKAGE_RECEIVED, data: payroll?.data?.resMakePurchase ? payroll.data.resMakePurchase : {} });
  } catch (err) {
    yield put({ type: types.ADD_PAYROLL_PACKAGE_FAILED, err });
  }
}

export function* getPayrollPackBalance(action) {
  const api = API.create();

  try {
    api.setHeader(USER_AUTH_KEY, USER_AUTH_VALUE);
    let auth = yield call(api.market.auth);
    api.setHeader("auth", auth.data);
    const { params } = action;
    let payroll = yield call(api.payrollPackage.getPayrollBalance, params);
    if (payroll) {
      api.removeHeader("auth");
    }
    yield put({ type: types.GET_PAYROLL_PACKAGE_BALANCE_RECEIVED, data: payroll?.data ? payroll.data : '0' });
  } catch (err) {
    yield put({ type: types.GET_PAYROLL_PACKAGE_BALANCE_FAILED, err });
  }
}


export function* getPayrollDetail(action) {
  const api = API.create(config.urlProvisioning);

  try {
    api.setHeader(USER_AUTH_KEY, USER_AUTH_VALUE);
    let auth = yield call(api.market.auth);
    api.setHeader("auth", auth.data);
    const { params } = action;
    let payrolls = yield call(api.payrollPackage.getPayrollPurchase, params);

    yield put({
      type: types.GET_PAYROLL_PACKAGE_DETAIL_RECEIVED,
      data: payrolls.data ? payrolls.data : [],
    });
  } catch (err) {
    yield put({ type: types.GET_PAYROLL_PACKAGE_DETAIL_FAILED, err });
  }
}

export function* deletePayrollPackage(action) {
  const api = API.create(config.urlProvisioning);

  try {
    api.setHeader(USER_AUTH_KEY, USER_AUTH_VALUE);
    let auth = yield call(api.market.auth);
    api.setHeader("auth", auth.data);
    api.setHeader('name', getCookie('name'));
    api.setHeader('username', getCookie('email'));
    api.setHeader('backoffice-request-id', uuidV4Generator());
    const { params } = action;
    let payrolls = yield call(api.payrollPackage.deletePayrollPackage, params);

    yield put({ type: types.DELETE_BALANCE_RECEIVED, data: (payrolls.data?.status) ? true : false });
  } catch (err) {
    yield put({ type: types.DELETE_PAYROLL_PACKAGE_FAILED, err });
  }
}

export function* verifyNitEdition(action) {
  const api = API.create();
  const { params } = action;
  api.setHeader('authorization', `Bearer ${getCookie('token')}`);
  api.setHeader('backoffice-service-country', convert2AlphaCodeTo3AlphaCodeCountry(params.country))
  api.setHeader('backoffice-service-name', 'verify-nit-edition')

  try {
    let response = yield call(api.market.verifyNitEdition, params.data);

    yield put({ type: types.VERIFY_NIT_EDITION_RECEIVED, data: response.data });
  } catch (err) {
    yield put({ type: types.VERIFY_NIT_EDITION_FAILED, err });
  }
}
