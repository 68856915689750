import axios from 'axios';
import MarketHandler from './services/MarketHandler';
import { getCookie } from '../../utils/cookies';

const create = () => {
  const api = axios.create({
    headers: {
      'Cache-Control': 'no-cache',
      'content-type': 'application/json',
      'backoffice-auth': getCookie('token')
    },
    timeout: 240000
  });

  const marketHandler = new MarketHandler(api);

  return {
    setHeader: (key, value) => { api.defaults.headers.common[key] = value; },
    removeHeader: (key) => { delete api.defaults.headers.common[key]; },
    marketHandler
  };
};

export default {
  create
};
