import React, { useEffect, useState } from 'react';
import { COMPLEMENTS_TYPES_ENUM, LICENCE_TYPE, planSubType } from './plandata';
import { Field, Form, Formik } from 'formik';
import { returnFormCompanyDetailByCountry } from './model/formCompanyModel';
import { SiigoInputAtom } from '@siigo/siigo-input-atom-react';
import { SiigoSelectAtom } from '@siigo/siigo-select-atom-react';
import { SiigoDatepickerMolecule } from "@siigo/siigo-datepicker-molecule-react";
import { formatoFecha } from '../tools/format';
import { getAccess } from '../../utils/access';
import { ACCESS } from './access';
import { selectStates, PAYROLL_COMPANY_STATUS } from './state-company';
import { COUNTRYS, isPlanSubsTypeAvailable } from './utils';
import moment from 'moment';
import { SiigoButtonIconAtom } from '@siigo/siigo-button-icon-atom-react';
import { SiigoTooltipAtom } from '@siigo/siigo-tooltip-atom-react';
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import Loader from '../tools/loader';
import { getAddons } from './service';

const FormCompanyDetail = ({ country, company = {}, t, infoHasChanged, onSubmit, parentPath, resendWelcomeMail, isResendMail, isValidToEditNit, setHasCompanyChanges, cashiersAmount }) => {
    const [renderOptionsPrepaidPlanType, setRenderOptionsPrepaidPlanType] = useState([]);
    const [renderOptionsState, setRenderOptionsState] = useState([]);
    const [regexp] = useState(country.country === COUNTRYS.MEXICO.CODE ? /[^\w]*/g : /[^\d]*/g);
    const [disableSaveButton, setDisableSaveButton] = useState(true);

    useEffect(() => {
        setRenderOptionsPrepaidPlanType(optionsPrepaidPlanType);
        setRenderOptionsState(optionsState);
    }, [company]);

    const optionsPrepaidPlanType = () => {
        let allowedPlanSubType = [planSubType[1], planSubType[3]];
        if (company?.PrepaidPlanType == 2) allowedPlanSubType.push(planSubType[2])
        const planSubTypeFilter = Object.keys(planSubType).filter((_planSubType) => {
            if (company?.plan?.value == LICENCE_TYPE.SOLO_FACTURACION.value) {
                return allowedPlanSubType.includes(planSubType[_planSubType]);
            }
            return _planSubType && _planSubType != "null"
        });
        return planSubTypeFilter.map((_planSubType) => ({
            label: planSubType[_planSubType],
            value: _planSubType
        }));
    }

    const optionsState = () => Object.keys(selectStates).map((_state) => ({
        label: selectStates[_state],
        value: _state
    }));

    const ifAllowedPayrollUsers = () => {
        const allowedPayrolUsers = [
            LICENCE_TYPE.SIIGO_NUBE_INDEPENDIENTE.value,
            LICENCE_TYPE.SIIGO_NUBE_EMPRENDEDOR.value,
            LICENCE_TYPE.SIIGO_NUBE_PREMIUM.value,
            LICENCE_TYPE.SOLO_NOMINA.value,
            LICENCE_TYPE.SIIGO_NUBE.value
        ]

        return allowedPayrolUsers.includes(company?.plan?.value)
    }

    const validateAccessAndNit = () => getAccess(parentPath, ACCESS.Modify_Nit) && (isValidToEditNit || getAccess(parentPath, ACCESS.Modify_Nit_Special));

    const validateAndChangeFieldValue = (newValue, fieldName, onChangeFieldValue) => {
        onChangeFieldValue(fieldName, newValue);

        const disableButton = company[fieldName]?.toString() === newValue ? true : false;
        setDisableSaveButton(disableButton);
        setHasCompanyChanges(!disableButton);
    }

    return (
        <Formik
            initialValues={company}
            enableReinitialize={true}
            validationSchema={returnFormCompanyDetailByCountry(country.country, t)}
            onSubmit={(values, { setSubmitting }) => {
                onSubmit(values);
                setSubmitting(false);
            }}
        >
            {({ errors, values, setFieldValue }) => {
                return <Form>
                    <div className='form-company-details'>
                        <div className='form-company-colum'>
                            <div className="form-company-input-container">
                                <Field name="plan">{({ field }) => (
                                    <SiigoSelectAtom
                                        options={Object.values(LICENCE_TYPE).map((_license) => ({
                                            label: _license.name,
                                            value: _license.value
                                        }))}
                                        name={field.name}
                                        id={field.name}
                                        label={t("market.companyDetail.form.plan.placeHolder")}
                                        value={field.value?.value}
                                        disabled
                                    />
                                )}
                                </Field>
                            </div>
                            <div className="form-company-input-container">
                                <Field name="companyKey">{({ field }) => (
                                    <SiigoInputAtom
                                        align="left"
                                        id={field.name}
                                        name={field.name}
                                        label={t("market.companyDetail.form.companyKey.placeHolder")}
                                        type="text"
                                        value={field.value}
                                        readonly
                                    />)}
                                </Field>
                            </div>
                            <div className="form-company-input-container">
                                <Field name="PrepaidPlanType">{({ field }) => (
                                    <SiigoSelectAtom
                                        options={renderOptionsPrepaidPlanType}
                                        name={field.name}
                                        id={field.name}
                                        label={t("market.companyDetail.form.prepaidPlanType.placeHolder")}
                                        value={field.value}
                                        onChangeData={(_event) => validateAndChangeFieldValue(_event?.target?.value, field.name, setFieldValue)}
                                        disabled={!(getAccess(parentPath, ACCESS.Modify_Plan_Type) && !isPlanSubsTypeAvailable(company?.plan?.value))}
                                    />
                                )}
                                </Field>
                            </div>
                            <div className="form-company-input-container">
                                <Field name="userFullName">{({ field }) => (
                                    <SiigoInputAtom
                                        align="left"
                                        id={field.name}
                                        name={field.name}
                                        label={t("market.companyDetail.form.userFullName.placeHolder")}
                                        type="text"
                                        value={field.value}
                                        readonly
                                    />)}
                                </Field>
                            </div>
                            <div className="form-company-input-container welcome-email">
                                <Field name="welcomeEmail">{({ field }) => (
                                    <SiigoInputAtom
                                        align="left"
                                        id={field.name}
                                        label={t("market.companyDetail.form.welcomeEmail.placeHolder")}
                                        name={field.name}
                                        type="text"
                                        value={field.value ? t("market.companyDetail.form.welcomeEmail.send") : t("market.companyDetail.form.welcomeEmail.notSend")}
                                        readonly
                                    />)}
                                </Field>
                                {getAccess(parentPath, ACCESS.Send_mail_company) &&
                                    (isResendMail ? <Loader className="loader" /> :
                                        <SiigoTooltipAtom label={t("market.companyDetail.form.welcomeEmail.reSend")}>
                                            <SiigoButtonIconAtom
                                                color='secondary'
                                                icon='fa-solid fa-reply-all'
                                                onClick={resendWelcomeMail}
                                            />
                                        </SiigoTooltipAtom>)
                                }
                            </div>
                            <div className="form-company-input-container">
                                <Field name="name">{({ field }) => (
                                    <SiigoInputAtom
                                        align="left"
                                        id={field.name}
                                        name={field.name}
                                        label={t("market.companyDetail.form.name.placeHolder")}
                                        type="text"
                                        value={field.value}
                                        onchange={(_event) => validateAndChangeFieldValue(_event?.target?.value, field.name, setFieldValue)}
                                        readonly={!getAccess(parentPath, ACCESS.Change_Name)}
                                    />)}
                                </Field>
                            </div>
                            {company?.quoteNumber !== null && company?.plan?.value !== LICENCE_TYPE.SIIGO_NUBE_GESTION_PREMIUM.value &&
                                <div className="form-company-input-container">
                                    <Field name="quoteNumber">{({ field }) => (
                                        <SiigoInputAtom
                                            align="left"
                                            id={field.name}
                                            name={field.name}
                                            label={t("market.companyDetail.form.quoteNumber.placeHolder")}
                                            type="text"
                                            value={field.value}
                                            onchange={(_event) => validateAndChangeFieldValue(_event?.target?.value.toString().replace(regexp, ''), field.name, setFieldValue)}
                                            readonly={!getAccess(parentPath, ACCESS.Modify_Quotation)}
                                        />)}
                                    </Field>
                                </div>
                            }
                            <div className="form-company-input-container">
                                <Field name="users">{({ field }) => (
                                    <SiigoInputAtom
                                        align="left"
                                        id={field.name}
                                        name={field.name}
                                        label={t("market.companyDetail.form.users.placeHolder")}
                                        type="text"
                                        value={field.value}
                                        onchange={(_event) => validateAndChangeFieldValue(_event?.target?.value.toString().replace(regexp, ''), field.name, setFieldValue)}
                                        readonly={!getAccess(parentPath, ACCESS.Modify_Users_With_Access)}
                                    />)}
                                </Field>
                            </div>
                            {ifAllowedPayrollUsers() &&
                                <div className="form-company-input-container">
                                    <Field name="payrollUsers">{({ field }) => (
                                        <SiigoInputAtom
                                            align="left"
                                            id={field.name}
                                            label={t("market.companyDetail.form.payrollUsers.placeHolder")}
                                            name={field.name}
                                            type="text"
                                            value={field.value}
                                            onchange={(_event) => validateAndChangeFieldValue(_event?.target?.value.toString().replace(regexp, ''), field.name, setFieldValue)}
                                            readonly={!getAccess(parentPath, ACCESS.Modify_Payroll_Employees)}

                                        />)}
                                    </Field>
                                </div>
                            }
                            <div className="form-company-input-container">
                                <Field name="spaceAvailable">{({ field }) => (
                                    <SiigoInputAtom
                                        align="left"
                                        id={field.name}
                                        name={field.name}
                                        label={t("market.companyDetail.form.spaceAvailable.placeHolder")}
                                        type="text"
                                        value={field.value}
                                        readonly
                                    />)}
                                </Field>
                            </div>
                        </div>
                        <div className='form-company-colum'>
                            <div className="form-company-input-container">
                                <Field name="menuVersionDB">{({ field }) => (
                                    <SiigoInputAtom
                                        align="left"
                                        id={field.name}
                                        name={field.name}
                                        label={t("market.companyDetail.form.marketVersion.placeHolder")}
                                        type="text"
                                        value={field.value}
                                        readonly
                                    />)}
                                </Field>
                            </div>
                            <div className="form-company-input-container">
                                <Field name="date">{({ field }) => (
                                    <SiigoDatepickerMolecule
                                        id="date"
                                        name="date"
                                        value={formatoFecha(field.value)}
                                        readonly
                                        textOptions={[
                                            {
                                                hiddenHint: true,
                                                label: t("market.companyDetail.form.date.placeHolder")
                                            }
                                        ]}
                                    />
                                )}
                                </Field>
                            </div>
                            <div className="form-company-input-container">
                                <Field name="state">{({ field }) => (
                                    <SiigoSelectAtom
                                        options={renderOptionsState}
                                        name={field.name}
                                        id={field.name}
                                        label={t("market.companyDetail.form.state.placeHolder")}
                                        value={field.value}
                                        disabled={!getAccess(parentPath, ACCESS.Modify_Status)}
                                        onChangeData={(_event) => {
                                            const { value } = _event.target;
                                            setFieldValue('state', value);
                                            validateAndChangeFieldValue(value, 'state', setFieldValue);
                                            setFieldValue('active', (PAYROLL_COMPANY_STATUS?.ACTIVE == value || PAYROLL_COMPANY_STATUS?.READONLY == value))
                                        }}
                                    />
                                )}
                                </Field>
                            </div>
                            {values.state == PAYROLL_COMPANY_STATUS?.READONLY &&
                                <div className="form-company-input-container">
                                    <Field name="inactivationDate">{({ field }) => (
                                        <SiigoDatepickerMolecule
                                            id="inactivationDate"
                                            name="inactivationDate"
                                            value={formatoFecha(company.inactivationDate)}
                                            textOptions={[
                                                {
                                                    hiddenHint: true,
                                                    label: t("market.companyDetail.form.inactivationDate.placeHolder")
                                                },
                                                { label: '' }
                                            ]}
                                            dateRanges={[
                                                {
                                                    min: moment(new Date()).add(1, 'day').format('DD/MM/YYYY'),
                                                    max: ''
                                                }
                                            ]}
                                            onDateChange={(_event) => validateAndChangeFieldValue(_event?.target?.value, field.name, setFieldValue)}
                                        />
                                    )}
                                    </Field>
                                </div>
                            }
                            <div className="form-company-input-container">
                                <Field name="userEmail">{({ field }) => (
                                    <SiigoInputAtom
                                        align="left"
                                        id={field.name}
                                        name={field.name}
                                        label={t("market.companyDetail.form.userEmail.placeHolder")}
                                        type="text"
                                        value={field.value}
                                        readonly
                                    />)}
                                </Field>
                            </div>
                            <div className="form-company-input-container valida-nit">
                                <Field name="nit">{({ field }) => (
                                    <SiigoInputAtom
                                        align="left"
                                        id={field.name}
                                        name={field.name}
                                        label={t("market.companyDetail.form.nit.placeHolder")}
                                        type="text"
                                        value={field.value}
                                        onchange={(_event) => validateAndChangeFieldValue(_event?.target?.value, field.name, setFieldValue)}
                                        readonly={!validateAccessAndNit()}
                                    />)}
                                </Field>
                                {
                                    !isValidToEditNit ?
                                        <SiigoTooltipAtom label={'Cliente ya es facturador electrónico'}>
                                            <SiigoButtonIconAtom
                                                color='secondary'
                                                icon='fa-solid fa-question'
                                            />
                                        </SiigoTooltipAtom>
                                        : ''
                                }
                            </div>
                            {(company?.workflowInstanceCode === null || company?.workflowInstanceCode === "") || (getAccess(parentPath, ACCESS.Modify_Billing_Task) && getAccess(parentPath, ACCESS.Modify_Billing_Task_Force)) &&
                                <div className="form-company-input-container">
                                    <Field name="workflowInstanceCode">{({ field }) => (
                                        <SiigoInputAtom
                                            align="left"
                                            id={field.name}
                                            name={field.name}
                                            label={t("market.companyDetail.form.workflowInstanceCode.placeHolder")}
                                            type="text"
                                            value={field.value}
                                            onchange={(_event) => validateAndChangeFieldValue(_event?.target?.value.toString().replace(regexp, ''), field.name, setFieldValue)}
                                            readonly={!getAccess(parentPath, ACCESS.Modify_Billing_Task)}
                                        />)}
                                    </Field>
                                </div>
                            }
                            {country.country === COUNTRYS.COLOMBIA.CODE && <div className="form-company-input-container">
                                <Field name="pos">{({ field }) => (
                                    <SiigoInputAtom
                                        align="left"
                                        id={field.name}
                                        name={field.name}
                                        label={t("market.companyDetail.form.pos.placeHolder")}
                                        type="text"
                                        value={field.value}
                                        onchange={(_event) => validateAndChangeFieldValue(_event?.target?.value.toString().replace(regexp, ''), field.name, setFieldValue)}
                                        readonly={!getAccess(parentPath, ACCESS.Modify_POS)}
                                    />)}
                                </Field>
                            </div>}
                            <div className="form-company-input-container">
                                <Field name="serial">{({ field }) => (
                                    <SiigoInputAtom
                                        align="left"
                                        id={field.name}
                                        name={field.name}
                                        label={t("market.companyDetail.form.serial.placeHolder")}
                                        type="text"
                                        value={field.value}
                                        onchange={(_event) => validateAndChangeFieldValue(_event?.target?.value.toString().replace(regexp, ''), field.name, setFieldValue)}
                                        readonly={!getAccess(parentPath, ACCESS.Modify_Serial)}
                                    />)}
                                </Field>
                            </div>
                            {country.country === COUNTRYS.COLOMBIA.CODE &&
                                <div className="form-company-input-container valida-nit">
                                    <Field name="ActiveCashiers">{() => (
                                        <SiigoInputAtom
                                            align="left"
                                            id="cashiersid"
                                            name="Cajas"
                                            label={t("market.companyDetail.form.cashiers.placeHolder")}
                                            type="text"
                                            value={cashiersAmount}
                                            readonly
                                        />)}
                                    </Field>
                                    {
                                        (cashiersAmount == null) ?
                                            <SiigoTooltipAtom label={t("market.companyUsers.form.tooltipBox.label")}>
                                                <SiigoButtonIconAtom
                                                    icon="fa fa-question"
                                                    color="secondary"
                                                />
                                            </SiigoTooltipAtom>
                                            : ''}
                                </div>
                            }
                        </div>
                    </div>
                    <SiigoButtonAtom
                        className='save-button'
                        text={t('save')}
                        size='l'
                        onClick={() => onSubmit(values)}
                        disabled={infoHasChanged || disableSaveButton}
                    />
                </Form>
            }}
        </Formik >
    )
}

export default FormCompanyDetail;